import React, { useEffect } from "react";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "@material-ui/core/Modal";
import Select from "@material-ui/core/Select";
import Swal from "sweetalert2";

import SButton from "~/components/Buttons/SButton";
import Flex from "~/components/Containers/Flex";
import ModalContainer from "~/components/Containers/ModalContainer";
import Wrapper from "~/components/Containers/Wrapper";
import labService from "~/utils/api/v1/labService";
import packService from "~/utils/api/v1/packService";
import { CheckoutLabService, DetailedLabService } from "~/utils/interfaces/LabServices";

interface Props {
  id: string;
  modal: boolean;
  packLabServices: CheckoutLabService[];
  setError: (error: any) => void;
  setModal: (modal: boolean) => void;
  setPackLabServices: (packServices: CheckoutLabService[]) => void;
}

const LabServicesListModal = (props: Props): JSX.Element => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [labServices, setLabServices] = React.useState<DetailedLabService[]>([]);
  const [selectedLabService, setSelectedLabService] = React.useState<DetailedLabService[]>([
    {
      id: "",
      name: "",
      price: 0,
    },
  ]);
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const fetchServices = async (): Promise<void> => {
    try {
      setLoading(true);
      const req = await labService.fetchOrderedServices();
      setLabServices(
        req.data.data.map((service: DetailedLabService) => ({
          id: service.id,
          name: service.name,
          price: service.price,
          lab_name: service.lab_name,
          widgets: service.widgets,
        })),
      );
    } catch (err) {
      props.setError(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (props.modal) {
      fetchServices();
    }
  }, [props.modal]);

  const handleSelect = (serviceId: string): void => {
    if (serviceId) {
      const service = labServices.filter((service: DetailedLabService) => service.id === serviceId)[0];
      setSelectedLabService([service]);
    } else {
      setSelectedLabService([]);
    }
  };

  const handleSubmit = async (): Promise<void> => {
    if (selectedLabService.length > 0) {
      const body = {
        lab_services: [selectedLabService[0].id],
      };
      setSubmitting(true);
      await packService
        .addServiceToPack(props.id, body)
        .then(() => {
          const service = {
            id: selectedLabService[0].id,
            name: selectedLabService[0].name,
          };
          props.setPackLabServices([...props.packLabServices, service]);
          setSelectedLabService([]);
          props.setModal(false);
        })
        .catch((err) => {
          props.setError(err);
        });
    } else {
      Swal.fire({
        title: "Error",
        text: "Por favor selecciona un servicio",
        icon: "error",
      });
    }
    setSubmitting(false);
  };

  return (
    <Modal
      open={props.modal}
      onClose={() => {
        props.setModal(false);
        document.getElementById("navbar").style.zIndex = "1999";
      }}
      style={{ height: "100%" }}
    >
      <ModalContainer>
        <Flex justify="center">
          <Wrapper variant="outlined">
            <h2>Agregar servicio</h2>
            <FormControl>
              <InputLabel>{loading ? "Cargando servicios..." : "Servicio"}</InputLabel>
              <Select
                disabled={loading || submitting}
                value={selectedLabService.length > 0 ? selectedLabService[0].id : ""}
                onChange={(e) => {
                  handleSelect(e.target.value as string);
                }}
              >
                {labServices.map((service: DetailedLabService) => {
                  return (
                    <MenuItem
                      key={service.id}
                      value={service.id}
                    >
                      {`${service.name} - $${service.price} - ${service.lab_name} ${
                        service.widgets ? service.widgets?.join(", ") : null
                      }`}
                    </MenuItem>
                  );
                })}
              </Select>
              <Flex
                align="center"
                justify="center"
                margin="2rem 1rem 1rem 1rem"
              >
                <SButton
                  variant="outlined"
                  color="primary"
                  disabled={loading || submitting}
                  onClick={handleSubmit}
                >
                  {submitting ? "Agregando..." : "Agregar"}
                </SButton>
              </Flex>
            </FormControl>
          </Wrapper>
        </Flex>
      </ModalContainer>
    </Modal>
  );
};

export default LabServicesListModal;
