import { AxiosResponse } from "axios";
import qs from "qs";

import api from "~/utils/api/api";
import { CartItemObjectType } from "~/utils/interfaces/cartItem";
import { Locale } from "~/utils/interfaces/Locale";
import { GetItemsApiServiceProps, RequestResponse } from "~/utils/interfaces/request";
import { GetPaginatedRawDataItemsPayload, RawDataItemType } from "~/utils/interfaces/Services";
import { parseItems } from "~/utils/parser/services";

const labServicesBaseUrl = "lab-services";

type filterByCategoryProps = {
  category?: string;
  allow_zero_price?: boolean;
};
class LabService {
  id: any;

  async fetchLabServices(lab_id: string): Promise<AxiosResponse> {
    return api.get(`${labServicesBaseUrl}/filter_by_lab/`, {
      params: { lab: lab_id },
    });
  }

  async createLabService(data: any): Promise<AxiosResponse> {
    return api.post(`${labServicesBaseUrl}/`, (data = data));
  }

  async updateLabService(lab_service_id: string, data: any): Promise<AxiosResponse> {
    return api.patch(`${labServicesBaseUrl}/${lab_service_id}/`, (data = data));
  }

  async deleteLabService(lab_service_id: string): Promise<AxiosResponse> {
    return api.delete(`${labServicesBaseUrl}/${lab_service_id}/`);
  }

  async filterByCategory(params?: filterByCategoryProps): Promise<AxiosResponse> {
    return api.get(`${labServicesBaseUrl}/filter_by_category/`, { params });
  }

  fetchOrderedServices = async (locale?: Locale, comuna?: string, source?: string) => {
    if (source === "marketplace") {
      source = undefined;
    }
    return api.get(`${labServicesBaseUrl}/ordered_available_services/`, {
      params: { country: locale, comuna, source },
    });
  };

  fetchAvailableServices = async ({
    signal,
    params,
  }: GetItemsApiServiceProps): Promise<RequestResponse<CartItemObjectType[]>> => {
    let data: RawDataItemType[] = [];
    const res = await api.get<GetPaginatedRawDataItemsPayload>(
      `/v2/items/?${qs.stringify(
        { ...params, limit: 200 },
        {
          arrayFormat: "repeat",
          addQueryPrefix: false,
          encode: false,
          indices: false,
        },
      )}`,
      { signal },
    );
    const count = res.data.count || 200;
    data = data.concat(res.data.results);
    for (let i = 200; i < count; i += 200) {
      const res = await api.get<GetPaginatedRawDataItemsPayload>(
        `/v2/items/?${qs.stringify(
          { ...params, limit: 200, offset: i },
          {
            arrayFormat: "repeat",
            addQueryPrefix: false,
            encode: false,
            indices: false,
          },
        )}`,
        { signal },
      );
      data = data.concat(res.data.results);
    }
    data = parseItems(data.filter(({ price }) => price));
    return { data };
  };
}

const labService = new LabService();

export default labService;
