import React, { useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

import PrivateRoute from "~/components/Authentication/PrivateRoute";
import CardContainer from "~/components/Containers/CardContainer";
import Flex from "~/components/Containers/Flex";
import { SectionHeader, SpacedPaper } from "~/components/Containers/SpacedPaper";
import LabServicesListModal from "~/components/LabServices/LabServicesListModal";
import PackLabServiceCard from "~/components/LabServices/PackLabServiceCard";
import LoadingError from "~/components/Loaders/LoadingError";
import PackData from "~/components/Pack/PackData";
import Bold from "~/components/Typography/Bold";
import packService from "~/utils/api/v1/packService";
import servicesService from "~/utils/api/v1/servicesService";
import { CheckoutLabService } from "~/utils/interfaces/LabServices";

interface Props {
  id: string;
}

const PackDetail = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>({});
  const [packData, setPackData] = useState<any>({});
  const [packLabServices, setPackLabServices] = useState<CheckoutLabService[]>([]);
  const [labServiceModal, setLabServiceModal] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setLoading(true);
      try {
        const reqs = await Promise.all([packService.fetchPack(props.id), servicesService.fetchServices()]);
        setPackData(reqs[0].data);
        setPackLabServices(reqs[0].data.services);
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  return (
    <PrivateRoute>
      <LoadingError
        loading={loading}
        error={error}
      />
      {!loading && (
        <>
          <PackData
            id={props.id}
            packData={packData}
            setError={setError}
          />
          <LabServicesListModal
            id={props.id}
            setError={setError}
            modal={labServiceModal}
            setModal={setLabServiceModal}
            packLabServices={packLabServices}
            setPackLabServices={setPackLabServices}
          />
          <SpacedPaper variant="outlined">
            <Flex direction="column">
              <SectionHeader>
                <Bold>Servicios</Bold>
                <ButtonGroup variant="text">
                  <Button
                    color="primary"
                    onClick={() => {
                      setLabServiceModal(true);
                      document.getElementById("navbar").style.zIndex = "0";
                    }}
                  >
                    Agregar
                  </Button>
                </ButtonGroup>
              </SectionHeader>
              <hr />
              <CardContainer padding="1rem">
                {packLabServices.map((packLabService: CheckoutLabService) => (
                  <PackLabServiceCard
                    id={props.id}
                    setError={setError}
                    packLabServices={packLabServices}
                    setPackLabServices={setPackLabServices}
                    key={packLabService.id}
                    labServiceId={packLabService.id}
                    name={packLabService.name}
                  />
                ))}
              </CardContainer>
            </Flex>
          </SpacedPaper>
        </>
      )}
    </PrivateRoute>
  );
};

export default PackDetail;
