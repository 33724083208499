import { AxiosResponse } from "axios";

import api from "~/utils/api/api";

const servicesUrl = "services";

class ServicesService {
  async fetchServices(search?: string, page?: number): Promise<AxiosResponse> {
    return api.get(`${servicesUrl}`, { params: { search, page } });
  }
}

const servicesService = new ServicesService();

export default servicesService;
